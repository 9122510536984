<template>
  <div class="elevator">
    <div class="gray-bg">
      <div class="hero">
        <div class="container h-100 content-overlay">
          <div class="row align-items-center h-100 w-100">
            <div class="col-md-5">
              <h1 class="wow fadeInUp" data-wow-delay="0s">Find your new dream job on CitronWorks</h1>      
              <h5 class="wow fadeInUp" data-wow-delay="0.2s">
                No bidding, no commissions and no fuss, just great jobs for you to apply for 24/7.</h5>
              <router-link tag="button" class="el-button el-button--success is-round wow fadeInUp" data-wow-delay="0.4s" to="/freelancersignup">Sign up now</router-link>
            </div>
          </div>
        </div>
        <div class="gradient-overlay"></div>
      </div>
      <div class="about">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-6 col-sm-9 col-12 wow fadeInUp" data-wow-delay="0s">
              <center>
              <h5>CitronWorks is a global marketplace for freelance developers, virtual assistants, customer support staff, lead generation experts, copywriters, social media and blogging professionals, all available for one-off projects or ongoing work.</h5>      
              </center>
            </div>
          </div>
        </div>
      </div>
      <center>
        <img src="images/green-divider.png" width="90" height="auto">
      </center>
    </div>
    <div class="how-it-works">
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-md-7 col-sm-9 col-12">
            <center>
              <h2 class="orange-text mb-0 wow fadeInUp" data-wow-delay="0.2s">How it works</h2>
            </center>
          </div>
        </div>
        <el-row :gutter="30" type="flex">
          <el-col :sm="8" :md="8" :lg="8">
            <div class="card wow fadeInUp" data-wow-delay="0s">
              <center>
                <img src="images/call-icon.png" width="90" height="auto" class="mb-4">
              </center>
              <h4 class="blue-text mb-3">Signup</h4>
              <h5>Signing up for CitronWorks take less than 5 minutes. The membership is free for freelancers and you do not pay any commissions. </h5>  
            </div>
          </el-col>
          <el-col :sm="8" :md="8" :lg="8">
            <div class="card wow fadeInUp" data-wow-delay="0.2s">
              <center>
                <img src="images/share-icon.png" width="90" height="auto" class="mb-4">
              </center>
              <h4 class="blue-text mb-3">Create your profile</h4>  
              <h5>You create a profile, pick your skills, availability and set your rates. All of our employers can browse your profile and invite you to jobs so make sure you set your skills correctly and have a great profile.</h5> 
              <!-- <div class="text-right">
                <el-button type="success" round>Learn More</el-button>  
              </div>    -->
            </div>
          </el-col>
          <el-col :sm="8" :md="8" :lg="8">
            <div class="card last wow fadeInUp" data-wow-delay="0.4s">
              <center>  
                <img src="images/rocket-icon.png" width="90" height="auto" class="mb-4">
              </center> 
              <h4 class="blue-text mb-3">Apply for jobs</h4>  
              <h5>You can also search our database of available jobs and apply direct. You will also be in the job pool for our Concierge service where we pick the best freelancers for our select employers.</h5>
              <!-- <div class="text-right">
                <el-button type="success" round>Learn More</el-button>  
              </div>   -->
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="text-center wow fadeInUp" data-wow-delay="0.6s">
                <router-link tag="button" class="el-button el-button--success is-round" to="/freelancersignup">Sign up now</router-link>
              </div>  
          </el-col>
        </el-row>
        <div class="why-cw">
          <center>
            <img src="images/green-divider.png" width="90" height="auto" class="divider">
          </center>
          <h2 class="mb-5 orange-text text-center wow fadeInUp" data-wow-delay="0s">Why choose <span style="font-weight: 600;">CitronWorks?</span></h2>
          <el-row type="flex" class="justify-content-center">
            
            <el-col :span="24" :md="24" :lg="23" type="flex" class="special-box justify-content-center wow fadeInUp" data-wow-delay="0.2s">
              <el-card class="special-pointers">
                <el-row :gutter="20" type="flex" class="justify-content-center">
                  <el-col :xs="24" :sm="5" :md="6" :lg="6" class="hidden-xs">
                    <img src="/images/pointers.png" class="w-100 d-block mx-auto" alt="">
                  </el-col>
                  <el-col :xs="24" :sm="18" :md="16" :lg="16">
                    <ul>
                      <li>No commissions, ever
                      <li>Free for freelancers</li>
                      <li>Contact the employer outside the platform as you wish</li>
                      <li>No bidding, you just put up your profile and set your desired rate</li>
                      <li>The employers can find you in our database and contact you direct</li>
                      <li>You have access to all jobs our employers post and it’s free to apply</li>
                    </ul>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
          <el-row>
            <center>
              <h5 class="wow fadeInUp mt-5" data-wow-delay="0.4s">If you have any questions, feel free to email</h5>
              <span class="h5 wow fadeInUp" id="obfuscated" data-wow-delay="0.6s"><canvas style="width: 155px !important; height: 24px !important; cursor: pointer;"></canvas></span> 
            </center>
          </el-row>
        </div>
      </div>
    </div>
    <div class="testimonials">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <center>
              <h5 class="wow fadeInUp" data-wow-delay="0.4s">What our members say</h5>
              <h2 class="mb-5 wow fadeInUp" data-wow-delay="0.6s">Testimonials</h2>    
            </center>  
          </div>
          <div class="col-md-7 wow fadeInUp" data-wow-delay="0.6s">
            <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/449685620?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>
          </div>
          <!-- <div class="col-md-6">
            <div class="row quotation">
              <div class="col-sm-1">
                <img src="images/quotation.png" width="30" height="auto" class="p-0">        
              </div>
              <div class="col-sm-11">
                <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                </h4>  
                <div class="text-right client mt-4">
                  <h5 class="bold">Client's name here</h5>
                  <h5 class=""><i>Director, Company Name</i></h5>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="faq">
      <div class="container card">
        <div class="row">
          <div class="col-md-4">
            <h5>HAVE QUESTIONS?</h5>
            <h2 class="orange-text">FAQ</h2>
            <p class="pr-5">We are here to help you 7 days a week and respond within 24 hours. Plus, you can find most answers to your questions right on this page.</p>
          </div>
          <div class="col-md-8">
            <el-collapse v-model="activeNames" accordion>
              <el-collapse-item title="Aliquam tincidunt mauris eu risus." name="1">
                <p>Consistent with real life: in line with the process and logic of real life, and comply with languages and habits that the users are used to;</p>
                <p>Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc.</p>
              </el-collapse-item>
              <el-collapse-item title="Nunc dignissim risus id metus." name="2">
                <p>Operation feedback: enable the users to clearly perceive their operations by style updates and interactive effects;</p>
                <p>Visual feedback: reflect current state by updating or rearranging elements of the page.</p>
              </el-collapse-item>
              <el-collapse-item title="Cras ornare tristique elit." name="3">
                <p>Simplify the process: keep operating process simple and intuitive;</p>
                <p>Definite and clear: enunciate your intentions clearly so that the users can quickly understand and make decisions;</p>
                <p>Easy to identify: the interface should be straightforward, which helps the users to identify and frees them from memorizing and recalling.</p>
              </el-collapse-item>
              <el-collapse-item title="Praesent placerat risus quis eros." name="4">
                <p>Decision making: giving advices about operations is acceptable, but do not make decisions for the users;</p>
                <p>Controlled consequences: users should be granted the freedom to operate, including canceling, aborting or terminating current operation.</p>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { WOW } from 'wowjs'
import EmailObfuscate from 'email-obfuscate';
//import ContactForm from '~/components/ContactForm'

export default {
  data() {
    return {
      activeName: '1',
      // obfuscatedEmail: '\u0074\u0065\u0061\u006d\u0040\u0063\u0069\u0074\u0072\u006f\u006e\u0077\u006f\u0072\u006b\u0073\u002e\u0063\u006f\u006d',
    };
  },
  mounted () {
    new WOW().init()
    const el = document.getElementById('obfuscated')
    EmailObfuscate(el, {
      // Email construct: name@domain.tld
      name: 'team',
      domain: 'citronworks',
      tld: 'com',
      // Alternate Text
      altText: 'Email Address'
    });
  },
}
</script>

<style lang="scss" scoped>
.blue-text {
  color: #223d5a;
}
.gray-bg {
  background-color: #f9f9f7;
}
.content-overlay{
    z-index: 3;
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}
.gradient-overlay {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: block; 
  background-image: -webkit-gradient(linear,left top,right top,color-stop(30%,#ec8435),to(transparent,60%));
  background-image: linear-gradient(90deg,#ec8435 30%,transparent 60%);
}
.hero {
  height: 600px;
  padding: 70px 0; 
  background-image: url('/images/freelancer-cover.jpg'); 
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
  color: white;
  position: relative;

  h1 {
    font-size: 3rem;
    font-weight: 600;
    text-shadow: 2px 0 7px #bc5a10;
    margin-bottom: 20px;
  }

  h5 {
    font-weight: 400;
    text-shadow: 2px 0 7px #bc5a10;
    margin-bottom: 20px;
  }
  button {
    // text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .4px;
    border-radius: 30px;
    padding: 15px 23px;
    width: fit-content;
    font-size: 1.05rem;
    margin-top: 15px;
  }
}
canvas {
  // width: 160px !important;
  // height: 24px !important;
}
h2 {
  font-weight: 600;
  font-size: 2.5rem;
}

h5 {
  font-size: 1.1rem !important;
  line-height: 1.6;
  letter-spacing: .2px;
  font-weight: 300;
}
.about {
  padding: 70px 0;
}
.how-it-works {
  background-image: url('/images/gray-bg.png');
  background-repeat: no-repeat; 
  background-size: contain;
  padding: 70px 0; 

  .card {
    background-image: url('/images/card-bg.png');
    background-repeat: no-repeat; 
    background-size: contain;
    border-radius: 5px;
    border: 1px solid #f9f9f7;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 50px 30px 30px 30px;
    height: 100%;
    position: relative;

    h4 {
      font-weight: 700;
    }
  }

  button {
    border-radius: 30px;
    padding: 15px 23px;
    width: fit-content;
    font-size: 1.15rem;
    margin-top: 30px;
    font-weight: 600;
    letter-spacing: .4px;
  }
}
.testimonials {
  padding: 70px 0; 
  background-image: url('/images/testimonials-bg.png'); 
  background-size: cover;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);

  h5 {
    text-transform: uppercase;
    color: white;
  }
  .client h5 {
    font-weight: 500;
    margin-bottom: 0;
    text-transform: capitalize;
  }
  .client h5.bold {
    font-weight: 600;
    text-transform: uppercase;
  }

  h2 {
   color: white; 
  }

  h4 {
    line-height: 1.5;
    font-weight: 400;
    color: white;
    font-size: 1.3rem;
  }
}
.faq {
  padding: 70px 0; 
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);

  .card {
    padding: 30px;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
  }
  .el-collapse {
    border: transparent;
  }
  .el-collapse-item:first-child {
    .el-collapse-item__wrap {
      border-top: none;
    }
  }

  h5 {
    text-transform: uppercase;
    font-weight: 400;
  }
}

@media (min-width: 768px) {
  .hero {
    background-position: center;
    background-size: cover;
  }
}
@media (min-width: 993px) {
  .hero {
    background-position: 0% 30%;
    background-size: cover;
  }
}
@media (min-width: 1200px) {
  .hero {
    background-position: right;
    background-size: 90%;
  }
}
@media (min-width: 1500px) {
  .hero {
    background-size: 70%;
    background-position: right 30%;
  }
}

@media (max-width: 1199px) {

}
@media (max-width: 992px) {
  
}
@media (max-width: 767px) {
  .hero {
    height: auto;
    background: none;
  }
  .gradient-overlay {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(30%, #ec8435), to(#ec8435bf));
    background-image: linear-gradient(0deg, #ec8435 30%, #ec8435bf);
  }
  .how-it-works .card {
    margin-bottom: 20px;
  }
  .how-it-works .card.last {
    margin-bottom: 0px;
  }
  .how-it-works .el-row--flex {
    display: block;
  }
  .hero h5, .hero h1 {
    max-width: 450px;
  }
  .quotation {
    margin-top: 30px;
  }
  .faq {
    padding: 0;
    box-shadow: none;
  }
  .faq .card {
    padding: 70px 15px;
    border-radius: 0;
  }
}
@media (max-width: 635px) { 
  .hero {
    background-position: left;
    background-size: 260%;
  }
}
</style>

<style>
@media (max-width: 767px) {
  .how-it-works .card {
    margin-bottom: 20px;
  }
  .how-it-works .card.last {
    margin-bottom: 0px;
  }
  .how-it-works .el-row--flex {
    display: block;
  }
  .how-it-works [class*=el-col-] {
    float: none;
  }
}
  .faq .el-collapse-item:last-child .el-collapse-item__header, .faq .el-collapse-item:last-child .el-collapse-item__wrap {
      border-bottom: 1px solid transparent !important;
  }
  .faq .el-collapse-item__header {
    color: #223d5a;
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: .2px;
    padding: 20px 0;
    height: auto;
    line-height: 1.6;
  }
  .faq .el-collapse-item__header:hover, .faq .el-collapse-item__header:focus { 
    color: #E87722;
  }
</style>
